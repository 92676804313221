export default `
<svg width="48" height="60" viewBox="0 0 48 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="path-1-inside-1_1536_38317" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M31.0326 46.9532C40.856 43.9471 48 34.8078 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 34.3472 6.54807 43.1651 15.7264 46.5358L23.5 60L31.0326 46.9532Z"/>
  </mask>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M31.0326 46.9532C40.856 43.9471 48 34.8078 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 34.3472 6.54807 43.1651 15.7264 46.5358L23.5 60L31.0326 46.9532Z" fill="#C40000"/>
<path d="M31.0326 46.9532L30.1547 44.0845L29.0252 44.4302L28.4345 45.4532L31.0326 46.9532ZM15.7264 46.5358L18.3245 45.0358L17.781 44.0944L16.7606 43.7197L15.7264 46.5358ZM23.5 60L20.9019 61.5L23.5 66L26.0981 61.5L23.5 60ZM31.9104 49.8219C42.9593 46.4408 51 36.1633 51 24H45C45 33.4524 38.7526 41.4534 30.1547 44.0845L31.9104 49.8219ZM51 24C51 9.08831 38.9117 -3 24 -3V3C35.598 3 45 12.402 45 24H51ZM24 -3C9.08831 -3 -3 9.08831 -3 24H3C3 12.402 12.402 3 24 3V-3ZM-3 24C-3 35.6454 4.37106 45.5615 14.6922 49.3519L16.7606 43.7197C8.72508 40.7687 3 33.0491 3 24H-3ZM13.1284 48.0358L20.9019 61.5L26.0981 58.5L18.3245 45.0358L13.1284 48.0358ZM26.0981 61.5L33.6307 48.4532L28.4345 45.4532L20.9019 58.5L26.0981 61.5Z" fill="white" mask="url(#path-1-inside-1_1536_38317)"/>
<path d="M40.0672 26.6269C40.0682 26.6514 40.0712 26.676 40.0712 26.7015V26.9059C40.0712 27.5798 39.6408 28.0868 38.9323 28.2921C38.0244 28.5545 37.0517 28.8816 35.3734 28.8826C33.2726 28.8836 31.9333 28.4759 30.6825 27.9512C28.5837 28.7274 26.816 29.3277 23.8417 29.3277C20.4861 29.3277 18.8717 28.6262 17.3585 28.0593C15.9072 28.7441 15.4169 28.8826 13.1108 28.8826C11.3519 28.8826 10.5629 28.7716 9.68346 28.3599C9.15286 28.1114 8.8178 27.5798 8.8178 27.0012V26.9059C8.8178 26.8106 8.82763 26.7182 8.84531 26.6278C8.27835 26.7035 8.18108 27.1112 8.18108 27.4973C8.18108 29.9604 9.484 31.5775 13.0095 31.5775C16.5351 31.5775 20.5441 31.2523 24.4302 31.2592C28.5168 31.267 32.122 31.5775 35.9649 31.5775C39.6958 31.5372 40.7796 30.0036 40.7796 27.4973C40.7796 26.9678 40.5546 26.6829 40.0702 26.6259L40.0672 26.6269Z" fill="white"/>
<path d="M40.7924 22.3305C40.7924 19.7083 38.3497 18.6925 36.0052 18.6925C34.6993 18.6925 33.5664 19.6759 33.1557 21.2999C31.4293 20.5051 32.6604 18.6895 34.0842 18.4301C31.5973 16.7953 29.0612 18.9253 28.5571 21.2999C26.6401 20.3194 28.622 17.9527 29.806 17.6835C26.6853 16.2265 24.1502 18.6571 23.7984 21.2999C21.6171 20.5051 23.8279 17.5017 25.0778 17.2227C21.9069 16.1734 19.4829 19.0501 18.8344 21.2999C16.8613 20.5051 19.0957 17.6668 20.2454 17.5783C16.9949 16.2727 14.8961 19.2259 14.1592 21.2999C12.306 20.5051 13.6954 18.7436 14.7448 18.4203C10.5806 17.1068 8.16339 19.7899 8.16339 22.5516C8.15946 22.6489 8.53285 22.7422 9.19708 22.8277C14.1081 23.4653 35.0776 23.7414 39.8294 22.7707C40.4455 22.6449 40.7934 22.4985 40.7934 22.3286L40.7924 22.3305Z" fill="white"/>
<path d="M8.69104 24.2296C8.7726 23.986 8.92785 23.5272 9.18529 23.5272C9.18529 23.7021 9.10472 23.8858 9.12142 24.096C9.14795 24.436 10.7879 24.9007 12.9869 24.9007C15.186 24.9007 15.9504 24.7533 17.4794 24.0253C18.8147 24.6423 20.8575 24.8643 23.5872 24.8643C26.3168 24.8643 27.8929 24.6049 29.9544 24.0891C31.723 24.6482 32.6801 24.8329 34.9735 24.8329C37.1254 24.8329 38.2976 24.7209 39.8226 23.8563C39.8226 23.7463 39.8226 23.6775 39.8226 23.4309C40.6715 23.5183 40.0397 24.714 42 25.0451V25.2052C41.664 25.6866 40.6568 26.0099 39.6349 26.0207C38.4607 26.0325 37.9714 26.3793 37.3455 27.178H37.1686C36.4474 26.4618 35.4864 26.0305 34.4114 26.0305C33.0387 26.0305 32.5691 26.3469 31.8311 27.0768H31.6415C30.9959 26.4225 30.2914 25.9843 29.1644 25.9843C27.9558 25.9843 27.0754 26.6229 26.5281 27.4099L26.3787 27.4158C25.8471 26.6681 24.9088 26.1641 23.6658 26.1641C22.6449 26.1641 21.3331 26.7467 20.7602 27.5464H20.605C20.1786 26.6691 19.0181 26.1523 18.0129 26.1523C16.6363 26.1523 15.7058 26.7585 15.3285 27.5013L15.1398 27.5091C14.5955 26.7909 13.8035 26.1661 12.7472 26.1661C11.6909 26.1661 10.6926 26.5699 10.1856 27.2222L10.0598 27.2242C9.24326 26.2014 8.26067 25.7033 7 25.61V25.3781C7.7114 25.218 8.4562 24.9351 8.69104 24.2306V24.2296Z" fill="white"/>
  </svg>
`;
