import styled from 'styled-components';

const Button = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: 1px solid ${Styles.color.grey.four};
  border-radius: 25px;
  font-size: 1.5rem;
  color: ${Styles.color.primary};
  padding-block-start: 0;
  padding-block-end: 10px;
  padding-inline: 0;
  margin: 0;
  height: 35px;
  width: 35px;
  font-family: ${Styles.fontFamily.base};
  &[disabled] {
    color: ${Styles.color.grey.four};
  }
`;

export default Button;
