import styled from 'styled-components';

import { IValueProps } from './types';

const Value = styled.div<IValueProps>`
  min-width: 22px;
  text-align: center;
  margin: 0 1rem;
  font-size: 1rem;
  padding-block-end: 6px;
`;

export default Value;
