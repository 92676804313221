import { ActionButtonVariants } from 'components/action-button';

export default {
  itemNameFont: Styles.fontFamily.base,
  itemContainerMarginTop: '2rem',
  itemBackground: 'none',
  itemBorderRadius: 0,
  itemImageBackgroundColor: '#d8d8d8',
  itemBorder: '1px solid #d8d8d8',
  itemPadding: '0.65rem 0',
  contentWidth: '280px',
  contentPadding: false,
  emptyCartBtnVariant: ActionButtonVariants.PRIMARY,
  buttonContainerMargin: '4rem 0 2rem',
};
